import { api } from '@converse/headless/core';
import { CustomElement } from './element.js';
import { html } from 'lit';


export class ConverseBrandLogo extends CustomElement {

    render() { // eslint-disable-line class-methods-use-this
        const is_fullscreen = api.settings.get('view_mode') === 'fullscreen';

        /**
         * App brand name
         * @type {{title: string; sub?:string}}
         */
        let app_brand_name = api.settings.get('app_brand_name');

        /**
         * App brand logo
         * @type {{src:string; width?: string; height?: string;}}
         */
        let app_brand_logo = api.settings.get('app_brand_logo');

        // app name defined
        if (app_brand_name) {
            if (typeof app_brand_name === "string") {
                app_brand_name = {
                    title: app_brand_name,
                    sub: undefined
                };
            }
        } else if (!app_brand_logo) {
            app_brand_name = {
                title: 'live',
                sub: 'chat'
            };
        }
        return html`
            <a class="brand-heading" href="https://conversejs.org" target="_blank" rel="noopener">
                <span class="brand-name-wrapper ${is_fullscreen ? 'brand-name-wrapper--fullscreen' : ''}">
                    ${app_brand_logo ? html`<img src="${app_brand_logo.src}" ${app_brand_logo.width ? `width="${app_brand_logo.width}"` : ''}  ${app_brand_logo.height ? `height="${app_brand_logo.height}"` : ''}/>` : ''}
                    ${app_brand_name ? html`<span class="brand-name">
                        <span class="brand-name__text">${app_brand_name.title}<span class="subdued">${app_brand_name.sub ? `${app_brand_name.sub}` : ''}</span></span>
                        ${is_fullscreen
                    ? html`
                                <p class="byline">messaging freedom</p>
                            `
                    : ''}
                    </span>`: ''}
                </span>
            </a>
        `;
    }
}

api.elements.define('converse-brand-logo', ConverseBrandLogo);
